import NextLink from 'next/link';
import { AnchorHTMLAttributes, ReactElement } from 'react';

import Text, { Props as TextProps } from '@legacy-components/Text';
import { Color, TextStyle } from '@legacy-components/theme';

import { StyledLink } from './styledComponents';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export interface Props
  extends Pick<AnchorProps, Exclude<keyof AnchorProps, 'color'>>,
    Pick<TextProps, 'styleKey' | 'color'> {
  testID?: string;
}

const Link = ({
  children,
  styleKey = TextStyle.BODY_3,
  color = Color.PRIMARY,
  ...anchorProps
}: Props): ReactElement => {
  const vanillaLink = (
    <StyledLink {...anchorProps} color={color}>
      <Text styleKey={styleKey} color={color}>
        {children}
      </Text>
    </StyledLink>
  );
  if (!anchorProps.href) return vanillaLink;

  return (
    <NextLink href={anchorProps.href} legacyBehavior>
      {vanillaLink}
    </NextLink>
  );
};

export default Link;
